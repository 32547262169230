
import Headers from '@/components/header/index.vue';
import Footer from '@/components/footer/index.vue'
import {config} from "@/utils"
import VueQr from 'vue-qr'
export default {
    name: 'information',
    components:{
        Headers,
        Footer,
        VueQr
      
    },
    mounted () {
    },
    created(){
        this.getList()
        // this.getArticle()
        
        

    },
    methods: {
        getArticle(){
            this.$api.news.article_list({
                page:this.page,
                title:this.title,
                category_id: this.category_id,
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.daList=res.list

            }).catch(err => {
                console.log(err)
            })

        },
        getList(){
            let queryData = JSON.parse(localStorage.getItem('_RedirectData'))

            if(this.$route.query.hasOwnProperty('article_id')){
                this.article_id=this.$route.query.article_id
                this.see=this.$route.query.see
            }else{
                this.article_id=queryData.article_id
                this.see=queryData.see
            }
           
        //     let token=localStorage.getItem('USER__TOKEN')
        //     if (localStorage.getItem("USER__TOKEN") != null) {
        //         if (Object.keys(token).length != 0) {
        //             alert('有值')
        //         }else{
        //             alert('没有值')
        //         }
        //         alert('key 1存在')
        //     }else{ 
        //             alert('key 不存在')
        //     }
            
        //    return
            // let token=localStorage.getItem('USER__TOKEN')
         
            if(this.see ==1 ) {
                this.$api.news.article_detail({article_id:this.article_id,}).then(res => {
                    if ( res.status_code != 0) {
                        this.$router.push({
                            path:'/',
            
                        })
                    }
                    this.num= res.obj
                    console.log(this.num);
                    if(res.obj.like_count !=0){
                        this.cStatus1=1
    
                    }else{
                        this.cStatus1=0
                    }
                    if(res.obj.collect_count !=0){
                        this.cStatus2=1
    
                    }else{
                        this.cStatus2=0
                    }
                    // if(this.token !=null){
                    //     this.getCollect()//收藏列表

                    // }
                   
                    this.getCommentList()//评论列表
                    this.getSuggest()//热门文章


                  
    
                }).catch(err => {
                    console.log(err)
                })
    


            }else{
                this.$api.news.preview_detail({article_id: this.article_id}).then(res => {
                    if ( res.status_code != 0) {
                        this.$router.push({
                            path:'/',
            
                        })
                    }
                    this.num= res.obj
                    
                  
    
                }).catch(err => {
                    console.log(err)
                })

                
            }
            
            
        },
        // 点赞文章、评论
        
        addLike(e,v){
            if(e==1){
                this.$api.news.add_article_like({article_id: this.article_id}).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.getList()
                    // this.$Message.warning({
                    //     content: "已点赞"
                    // })
                    this.cStatus1=1
    
    
                    // this.collectList= res.list
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }else{
                this.$api.news.add_comment_like({comment_id: v.comment_id}).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.getCommentList()
                  
    
                }).catch(err => {
                    console.log(err)
                })



            }
            



        },
        //作者评论点赞
        addLikes(v){
            this.$api.news.add_comment_like({comment_id: v.reply_comment_id}).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.getCommentList()

            }).catch(err => {
                console.log(err)
            })



            



        },
        //收藏 
        addCollect(){
            this.$api.news.add_article_collect({article_id: this.article_id}).then(res => {
                // if ( res.status_code != 0) return this.$Message.warning({
                //     content:  res.status_msg
                // })
                this.getList()
                this.$Message.warning({
                    content: "已收藏"
                })
                this.cStatus2=1


                // this.collectList= res.list
              

            }).catch(err => {
                console.log(err)
            })

        },
        //取消收藏
        cancleCollect(v){
            this.$api.news.article_collect_cancel({article_id: v.article_id}).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.getCollect()
                this.getList()
            }).catch(err => {
                console.log(err)
            })

        },
        //查看收藏文章
        checkCollect(v){
            if(this.article_id==v.article_id){
                this.modal2=false
                this.modal3=false

            }else{
                this.$router.push({
                    path:'/details',
                    query:{article_id:v.article_id}
    
                })

            }
            

        },
        getCollect(){
            this.$api.news.collect_article_list().then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.collectList= res.list
              

            }).catch(err => {
                console.log(err)
            })



        },
        // 评论列表
        getCommentList(){
            this.$api.news.comment_list({article_id:this.article_id}).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.commentList= res.list
              

            }).catch(err => {
                console.log(err)
            })


        },
        //提交留言
        submit(){
            let is_anonymity
            if(this.single ==true){
                is_anonymity=1

            }else{
                is_anonymity=0
            }
            this.$api.news.add_comment({
                
                article_id: this.article_id,
                content:this.value2,
                is_anonymity:is_anonymity
            }).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.value2=''
                this.single =false
                this.getCommentList()
              

            }).catch(err => {
                console.log(err)
            })



        },
        // 举报
        getReport(e,v){
            this.report_type=e
            if(e==2){
                this.comment_id=v.comment_id

            }
            this.$api.news.report_item_list({
                
                report_type: e,
            }).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.reportList=res.list
                // this.value2=''
                this.modal1 =true
                // this.getCommentList()
              

            }).catch(err => {
                console.log(err)
            })

        },
        // 反馈记录
        record(){
            if(this.report_type ==1){
               
                this.$api.news.article_report_list({
                    
                }).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.articleList=res.list
                    this.modal1=false
                    this.modal2=true
                    
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }else{
                
                this.$api.news.comment_report_list({
                    
                   
                }).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.articleList=res.list
                    this.modal1=false
                    this.modal2=true
                   
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }

        },
        // 提交反馈
        submitRecord(){
            if(!this.formValidate.gender) return this.$Message.error({content: "请选择举报分类"})
            let arr=[]
            this.uploadList.forEach(ite => {
                arr.push(ite.file_path)
                
            });
            // 文章
            if(this.report_type ==1){
                this.$api.news.add_article_report({
                    
                    article_id:this.article_id,
                    suggest:this.suggest,
                    report_item_id:this.formValidate.gender,
                    img_paths:arr
                }).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.modal1=false
                    this.formValidate.gender=''
                    this.$Message.success({
                        content: "举报成功"
                    })
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }else{
                this.$api.news.add_comment_report({
                    
                    comment_id:this.comment_id,
                    suggest:this.suggest,
                    report_item_id:this.formValidate.gender,
                    img_paths:arr
                }).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.modal1=false
                    this.formValidate.gender=''
                    this.$Message.success({
                        content: "举报成功"
                    })
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }



        },


        handleView (name) {
            this.imgName = name;
            this.visible = true;
        },
        handleRemove (file) {
            const fileList = this.$refs.upload.fileList;
            this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
        },
        handleSuccess (response, file) {
            if(response.status_code==0){
                this.$Message.success({content:'上传成功'})
                this.uploadList.push(response.list[0]) 
              
            }else{
                this.$Message.warning({content: response.status_msg})
            }
        },
        handleFormatError (file) {
            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
            });
        },
        handleMaxSize (file) {
            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 2M.'
            });
        },
        handleBeforeUpload () {
            const maxCount = 3;
            if (this.$refs.upload.fileList.length == maxCount) {
              this.$Notice.error({ title: '错误', desc: '最多允许上传 ' + maxCount + ' 个文件' });
              return false;
            }
       
            // 通过判断，返回true继续上传
            return true;
        },
        // 返回
        go(e){
            if(e==1){
                this.$router.push({
                    path:'/',
    
                })
            }else{
                this.$router.push({
                    path:'/information',
    
                })

            }

        },
         // 热文推荐
         getSuggest(){
            this.$api.news.most_view_list({location: 2}).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                
                this.suggestList= res.list
                

            }).catch(err => {
                console.log(err)
            })


        },
        checkS(v){
            if(v.article_id !=this.article_id){
                this.$router.push({
                    path:'/details',
                    query:{article_id:v.article_id,see:1}
    
                })
    
    
            }
            this.getList()



        },
        // 分享
        fenx(){
            console.log(window.location);
            let redirectData=JSON.parse(localStorage.getItem('_RedirectData'))
            if(this.token !=null){
                this.text= window.location.origin+'/wap/details'+"?token="+redirectData.token+"&usertype="+redirectData.usertype+"&article_id="+ this.article_id+"&see="+ this.see


            }else{
                this.text= window.location.origin+'/wap/details'+window.location.search//二维码内容


            }
           
           
            this.modal4=true
           
          
        },
        qrCodeCallback (url) {
            this.qrCodeUrl = url
        },
        getQRcode(){
            let opts = {
                errorCorrectionLevel: "L",//容错级别
                type: "image/png",//生成的二维码类型
                quality: 0.3,//二维码质量
                margin: 5,//二维码留白边距
                width: 128,//宽
                height: 128,//高
                logoSrc:require('../../../../components/header/images/logo.png'), 
                text: window.location.origin+'/wap/details'+
                window.location.search,//二维码内容
                color: {
                    dark: "#666666",//前景色
                    light: "#fff"//背景色
                }
            }
            //this.QRlink 生成的二维码地址url
            VueQr.toDataURL(this.QRlink, opts , (err, url) => {
                if (err) throw err
                //将生成的二维码路径复制给data的QRImgUrl
                this.QRImgUrl= url
            })
        }
        
    },

    data(){
        return{
            config,
            article_id:'',
            see:'',
            seeShow:false,
            text: '', // 二维码实际内容
            logoSrc:require('../../../../components/header/images/logo.png'), // 二维码中间的图片
            randomColor: 'black', // 二维码颜色
            QRlink: '', // 存放生成的二维码url
            QRImgUrl: '', // 存放生成的二维码url
            suggestList:[],
            token:localStorage.getItem('USER__TOKEN'),
            num:{},
            query:{},
            uploadList:[],
            value2:"",//留言
            single: false,//匿名提交
            modal1: false,//举报反馈
            modal2:false,//我的反馈列表
            modal3:false,//我的收藏列表
            modal4:false,//分享
            commentList:[],//评论列表
            reportList:[],//举报选项
            articleList:[],//我的反馈列表
            suggest:'',//举报建议
            report_type:0,//举报文章、评论
            comment_id:0,//评论id
            cStatus1:0,
            cStatus2:0,
            cStatus3:0,
            collectList:[],
            imgName: '',
            visible: false,
            defaultList: [],
            //
            formValidate: {
               
                gender: '',
              
            },
            ruleValidate: {
            
                gender: [
                    { required: true, message: 'Please select gender', trigger: 'change' }
                ],
             
            }
           
            

        }
        
    }
    
}